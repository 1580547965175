import { VDirective, VServices } from '@libTs/vue-base';
import { DirectiveBinding } from 'vue/types/options';
import { SITEURL } from '../cms.globals';

export const FormDirective: VDirective = [
  'form', // translates to attribute "v-tabs" in HTML
  {
    bind( el: HTMLElement, binding: DirectiveBinding ): void {

      $( function () {
        let isSubmitting = false;
        // Find the form element
        const form = document.getElementById( 'isrc-keys-form' );

        if ( form ) {
          const loader = '<i class="far fa-spinner-third icon-loader"></i>';

          form.addEventListener( 'submit', function ( e ) {
            const button = $( '.btn-label' );

            if ( isSubmitting ) {
              e.preventDefault();
            } else {
              button.prop( 'disabled', 'true' );
              button.addClass( 'pointer-events-none' );
              isSubmitting = true;
            }
            $( '.icon-loader' ).remove();
            button.append( loader );
            button.prop( 'disabled', 'true' );
          } );

          form.addEventListener( 'freeform-render-success', function ( event ) {
            const button = $( '.btn-label' );
            const url = $( form ).data( 'redirecturl' );
            $( '.icon-loader' ).remove();
            button.prop( 'disabled', 'false' );
            button.removeClass( 'pointer-events-none' );
            isSubmitting = false;
            window.location.href = SITEURL + url;
          } );

          form.addEventListener( 'freeform-render-form-errors', function ( event ) {
            const button = $( '.btn-label' );
            isSubmitting = false;
            $( '.icon-loader' ).remove();
            button.prop( 'disabled', 'false' );
            button.removeClass( 'pointer-events-none' );
          } );
        }
      } );
    }
  }
];
